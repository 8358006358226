// import React, { useState, useEffect } from 'react';
// import { Row, Col, DatePicker, Button } from 'antd';
// import listMode from "../../assets/images/listmode.svg"
// import moment from 'moment';
// import { useHistory } from "react-router";
// import { Popover } from 'antd';
// import { useWindowContext, useGlobalContext } from "../../lib/storage";

// const CalenderView = (props) => {
//   // console.log(props, "----->props");
//   const { windowStore, setWindowStore } = useWindowContext();
//   const windowDefinition = { ...windowStore.windowDefinition };
//   const history = useHistory();

//   const [selectedDate, setSelectedDate] = useState(moment());
//   const [view, setView] = useState('month'); // Initial view

//   useEffect(() => {
//     setSelectedDate(moment()); // Set selected date to current date on component mount
//   }, []);

//   const onChange = (date) => {
//     if (date === null) {
//       // When the user clears the DatePicker, set the current date
//       setSelectedDate(moment());
//     } else {
//       // Otherwise, set the selected date
//       setSelectedDate(date);
//     }
//   };

//   const handleViewChange = (value) => {
//     setView(value);
//   };

//   const generateEventsForDate = (date) => {
//     const formattedDate = date.format('YYYY-MM-DD');

//     const eventsForDate = props.cardData
//       .filter(card => Object.keys(card).some(key => key.startsWith(formattedDate)))
//       .flatMap(card => {
//         const dateKey = Object.keys(card).find(key => key.startsWith(formattedDate));
//         return dateKey ? card[dateKey].flatMap(record => {
//           // console.log('Record Row1 Data:', record.recordRow1Data);

//           // Create the heading using keys and values from recordRow1Data
//           const headings = Object.entries(record.recordRow1Data[0]).map(([key, value]) => ({
//             title: ` ${value}`,
//             recordId: record.recordId,
//             title_field_color: record.title_field_color,
//             isHeading: true // Indicate this is a heading
//           }));

//           // Flatten the nested structure of titleData
//           const titles = Object.entries(record.recordTitlesData).flatMap(([outerKey, innerObj]) =>
//             Object.entries(innerObj).map(([key, titleName]) => ({
//               title: `${key}: ${titleName || ''}`, // Include key and value
//               recordId: record.recordId,
//               title_field_color: record.title_field_color,
//               isHeading: false // Indicate this is not a heading
//             }))
//           );

//           // Return the headings followed by the titles
//           return [...headings, ...titles];
//         }) : [];
//       });

//     return eventsForDate;
//   };

//   const findLatestMonthWithEvents = (cardData) => {
//     let latestMonth = null;
//     let latestYear = null;

//     cardData.forEach(card => {
//       Object.keys(card).forEach(dateKey => {
//         const date = moment(dateKey, 'YYYY-MM-DD HH:mm:ss');
//         const month = date.month();
//         const year = date.year();

//         if (!latestYear || year > latestYear || (year === latestYear && month > latestMonth)) {
//           latestMonth = month;
//           latestYear = year;
//         }
//       });
//     });

//     return { latestMonth, latestYear };
//   };

//   const renderDateCell = (date) => {
//     const events = generateEventsForDate(date);
//     const isCurrentDate = date.isSame(moment(), 'day'); // Check if date is current date

//     // Define styles for the current date
//     const currentDateStyle = isCurrentDate ? { borderRadius: '60%', backgroundColor: '#E2E7F8' } : {};

//     return (
//       <div style={{ background: "#fff", border: 'none', margin: '1.5px', display: 'flex', flexDirection: 'column', height: "20vh" }}>
//         <div style={{ padding: '5px', fontFamily: "Inter", fontSize: "12px", fontWeight: 600, display: 'flex', justifyContent: 'space-between' }}>
//           <p style={{ padding: "3px 9px", ...currentDateStyle, color: "#192228", opacity: 0.6 }}>{date.format('D')}</p>
//           <p style={{ margin: 0, color: "#192228", opacity: 0.6 }}>{date.format('ddd')}</p>
//         </div>
//         <style>
//           {`
//         div::-webkit-scrollbar {
//           width: 8px;
//           height: 2px;
//         }
//         div::-webkit-scrollbar-track {
//           background-color: #f1f1f1;
//         }
//         div::-webkit-scrollbar-thumb {
//           background-color: #C1C1C1;
//           border-radius: 4px;
//           min-width: 8px;
//           max-width: 8px;
//           min-height: 8px;
//           max-height: 8px;
//         }
//         div::-webkit-scrollbar-thumb:hover {
//           background-color: #7D7D7D;
//         }
//         `}
//         </style>
//         <div style={{ flex: 1, overflowY: 'auto' }}>
//           {events.map((event, index) => (
//             <Popover
//               key={index}
//               content={
//                 <div className="popover-content" style={{
//                   maxHeight: '100px', // Adjust height as needed
//                   maxWidth: '200px',  // Adjust width as needed
//                   overflowY: 'auto',
//                   overflowX: 'hidden',
//                   // padding: '0.5em'
//                 }}>
//                   {events
//                     .filter(e => e.recordId === event.recordId && !e.isHeading) // Filter to show only events related to this heading
//                     .map((filteredEvent, subIndex) => (
//                       <div style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "12px" }} key={subIndex} >
//                         {filteredEvent.title}
//                       </div>
//                     ))}
//                 </div>
//               }
//               placement="topLeft"
//               trigger="hover"
//             >
//               <div
//                 onClick={() => { history.push(`/window/${windowDefinition.ad_window_id}/${event.recordId}`) }}
//                 key={index}
//                 style={{
//                   marginBottom: "1vh",
//                   height: "3vh",
//                   color: "#192228",
//                   fontFamily: "Inter",
//                   fontSize: "12px",
//                   fontWeight: 400,
//                   padding: "0 0.5em",
//                   display: event.isHeading ? 'flex' : 'none',
//                   justifyContent: 'space-between',
//                   borderLeft: event.isHeading ? '3px solid #276EFA' : '',
//                   borderTopLeftRadius: '3px',
//                   borderBottomLeftRadius: '3px',
//                   cursor: "pointer",
//                   fontWeight: event.isHeading ? 'bold' : 'normal' // Apply bold styling to headings
//                 }}
//               >
//                 <span className='formRecordTitle1' style={{ fontFamily: "Inter", fontSize: "12px" }}>
//                   {event.isHeading ? event.title : null}
//                 </span>
//               </div>
//             </Popover>
//           ))}
//         </div>
//       </div>
//     );
//   };

//   const renderView = () => {
//     switch (view) {
//       case 'day':
//         return (
//           <div>
//             {/* Day view content */}
//             <p>Selected Date: {selectedDate?.format('YYYY-MM-DD')}</p>
//             {renderDateCell(selectedDate)}
//           </div>
//         );
//       case 'week':
//         // Generate dates for the week
//         const startDate = selectedDate?.clone().startOf('week');
//         const dates = [...Array(7)].map((_, index) => startDate.clone().add(index, 'days'));
//         return (
//           <div>
//             {/* Week view content */}
//             {dates.map((date, index) => (
//               <div key={index}>{renderDateCell(date)}</div>
//             ))}
//           </div>
//         );
//       case 'month':
//         // Generate dates for the month starting from Monday
//         const startOfMonth = selectedDate?.clone().startOf('month').startOf('week'); // Start from the first Monday of the month
//         const endOfMonth = selectedDate?.clone().endOf('month').endOf('week');
//         const monthDates = [];
//         let currentDate = startOfMonth?.clone();

//         // Loop through each day of the month
//         while (currentDate.isSameOrBefore(endOfMonth)) {
//           monthDates.push(currentDate.clone());
//           currentDate.add(1, 'day');
//         }

//         // Ensure each row contains exactly seven boxes
//         const rows = [];
//         for (let i = 0; i < monthDates.length; i += 7) {
//           const row = monthDates.slice(i, i + 7);
//           // Pad the row with null values if it has fewer than seven dates
//           while (row.length < 7) {
//             row.push(null);
//           }
//           rows.push(row);
//         }

//         return (
//           <div>
//             {/* Month view content */}
//             {rows.map((row, rowIndex) => (
//               <div key={rowIndex} style={{ display: 'flex', justifyContent: 'center' }}>
//                 {row.map((date, index) => (
//                   <div key={index} style={{ width: '14%', textAlign: 'center' }}>
//                     {date ? renderDateCell(date) : null}
//                   </div>
//                 ))}
//               </div>
//             ))}
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div >
//       <Row gutter={16} style={{marginRight:"1vw",marginLeft:"0.7vw"}} align="middle">
//       <Col span={8}>
//           <div style={{ textAlign: 'start', color: '#192228', fontFamily: 'Inter', fontSize: '24px', fontWeight: 600 }}>
//             {selectedDate?.format('MMMM YYYY')}
//           </div>
//         </Col>
//         <Col span={1}/>
//         <Col span={9} >
//           <Button.Group style={{ background: "#2F3856", borderRadius: "20px", fontFamily: "Inter", fontSize: "11px", fontWeight: 500, height: "5vh", padding: "7px",marginBottom:"0.5vh" }}>
//             <Button
//               style={{
//                 background: view === 'day' ? '#fff' : '#2F3856',
//                 color: view === 'day' ? '#2F3856' : '#fff',
//                 borderRadius: view === 'day' ? '20px' : "",
//                 border: "none",
//                 height: "3vh",
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//               onClick={() => handleViewChange('day')}
//             >
//               Day
//             </Button>
//             <Button
//               style={{
//                 background: view === 'week' ? '#fff' : '#2F3856',
//                 color: view === 'week' ? '#2F3856' : '#fff',
//                 borderRadius: view === 'week' ? '20px' : "",
//                 border: "none",
//                 height: "3vh",
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//               onClick={() => handleViewChange('week')}
//             >
//               Week
//             </Button>
//             <Button
//               style={{
//                 background: view === 'month' ? '#fff' : '#2F3856',
//                 color: view === 'month' ? '#2F3856' : '#fff',
//                 borderRadius: view === 'month' ? '20px' : "",
//                 // borderBottomRightRadius: '20px',
//                 border: "none",
//                 height: "3vh",
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//               onClick={() => handleViewChange('month')}
//             >
//               Month
//             </Button>
//           </Button.Group>
//         </Col>
//         <Col span={6} style={{textAlign:"end"}}>
//           <img src={listMode} onClick={()=>{props.setCalenderView(false)}} style={{marginRight:"0.5vw",marginBottom:"4px"}} alt=''/>
//           <DatePicker value={selectedDate} onChange={onChange} />
//         </Col>       
        
//       </Row>
//       <div style={{ height: "70vh", overflowY: "scroll", paddingBottom: "0vh" }}>{renderView()}</div>
//     </div>
//   );
// };

// export default CalenderView;

import React, { useState, useEffect } from 'react';
import { Row, Col, DatePicker, Button, Popover, message } from 'antd';
import { DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import listMode from "../../assets/images/listmode.svg";
import down from "../../assets/images/down.svg";
import moment from 'moment';
import { useHistory } from "react-router";
import { useWindowContext } from "../../lib/storage";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { upsertTabData } from '../../services/generic';

const CalenderView = (props) => {
  const { windowStore } = useWindowContext();
  const windowDefinition = { ...windowStore.windowDefinition };
  const history = useHistory();

  const [selectedDate, setSelectedDate] = useState(moment());
  const [view, setView] = useState('month');
  const [events, setEvents] = useState({});
  const [upsertSuccessFailedStatus, setUpsertSuccessFailedStatus] = useState("");
  const [upsertSuccessFailedMessage, setUpsertSuccessFailedMessage] = useState("");
  const [isUpsertRecordSuccess, setIsUpsertRecordSuccess] = useState(false);

  useEffect(() => {
    setSelectedDate(moment());
    initializeEvents();
  }, [props.cardData]);

  useEffect(() => {
    if (isUpsertRecordSuccess) {
      message[upsertSuccessFailedStatus](upsertSuccessFailedMessage);
      setIsUpsertRecordSuccess(false);
    }
  }, [isUpsertRecordSuccess, upsertSuccessFailedStatus, upsertSuccessFailedMessage]);

  const initializeEvents = () => {
    const newEvents = {};
    props.cardData.forEach(card => {
      Object.keys(card).forEach(dateKey => {
        if (dateKey.startsWith('20')) {
          const formattedDate = moment(dateKey).format('YYYY-MM-DD');
          if (!newEvents[formattedDate]) {
            newEvents[formattedDate] = [];
          }
          card[dateKey].forEach(record => {
            const headings = Object.entries(record.recordRow1Data[0]).map(([key, value]) => ({
              id: `${record.recordId}-heading`,
              title: ` ${value}`,
              recordId: record.recordId,
              title_field_color: record.title_field_color,
              isHeading: true,
              ...record
            }));

            const titles = Object.entries(record.recordTitlesData).flatMap(([outerKey, innerObj]) =>
              Object.entries(innerObj).map(([key, titleName]) => ({
                id: `${record.recordId}-${key}`,
                title: `${key}: ${titleName || ''}`,
                recordId: record.recordId,
                title_field_color: record.title_field_color,
                isHeading: false,
                ...record
              }))
            );

            newEvents[formattedDate].push(...headings, ...titles);
          });
        }
      });
    });
    setEvents(newEvents);
  };

  const onChange = (date) => {
    if (date === null) {
      // When the user clears the DatePicker, set the current date
      setSelectedDate(moment());
    } else {
      // Otherwise, set the selected date
      setSelectedDate(date);
    }
  };

  const handleViewChange = (value) => {
    setView(value);
  };

  const onDragEnd = (result) => {
  const kanbanConfiguration = JSON.parse(props.headerTab?.calenderview_configuration);
  const categorizeId = kanbanConfiguration.date_field_id;
  const { source, destination, draggableId } = result;

  if (!destination) {
    return;
  }

  if (destination.droppableId === source.droppableId && destination.index === source.index) {
    return;
  }

  const sourceDate = source.droppableId;
  const destDate = destination.droppableId;

  const newEvents = { ...events };
  const sourceEvents = [...(newEvents[sourceDate] || [])];
  const destEvents = [...(newEvents[destDate] || [])];

  const [movedEvent] = sourceEvents.splice(source.index, 1);
  destEvents.splice(destination.index, 0, movedEvent);

  newEvents[sourceDate] = sourceEvents;
  newEvents[destDate] = destEvents;

  setEvents(newEvents);

  // Prepare data for upsert
  const recordId = movedEvent.recordId;
  const updatedEvent = { ...movedEvent };


  for (let j in updatedEvent) {
    if (j.includes("_iden") || j.includes("key") || j === "recordId") {
      delete updatedEvent[j];
    }
  }
  const currentTime = new Date().toTimeString().split(" ")[0]; // Get the current time (HH:MM:SS)
  const formattedDateTime = `${destDate} ${currentTime}`; // Combine date and time

  const dateField = categorizeId; // The actual date field in your event object
  updatedEvent[dateField] = formattedDateTime; // Update the event's date with the destination date

 
  const stringifiedFields = JSON.stringify(updatedEvent);
  const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
  const stringRequest = JSON.stringify(updatedStrings);

  const headerTabId = props.headerTab.ad_tab_id;

  upsertTabData(windowDefinition.ad_window_id, headerTabId, recordId, stringRequest).then((upsertResponse) => {
    if (upsertResponse.data.data.upsertTab.status === "200") {
      setUpsertSuccessFailedStatus("success");
      setUpsertSuccessFailedMessage(upsertResponse.data.data.upsertTab.message);
      setIsUpsertRecordSuccess(true);
      props.setUpsertFlag(true);
    } else {
      setUpsertSuccessFailedMessage(upsertResponse.data.data.upsertTab.message);
      setUpsertSuccessFailedStatus("error");
    }
  });

  console.log(`Event ${draggableId} moved from ${sourceDate} to ${destDate}`);
};


  const navigatePrevious = () => {
    setSelectedDate(prevDate => {
      switch (view) {
        case 'day':
          return prevDate.clone().subtract(1, 'day');
        case 'week':
          return prevDate.clone().subtract(1, 'week');
        case 'month':
          return prevDate.clone().subtract(1, 'month');
        default:
          return prevDate;
      }
    });
  };

  const navigateNext = () => {
    setSelectedDate(prevDate => {
      switch (view) {
        case 'day':
          return prevDate.clone().add(1, 'day');
        case 'week':
          return prevDate.clone().add(1, 'week');
        case 'month':
          return prevDate.clone().add(1, 'month');
        default:
          return prevDate;
      }
    });
  };

  const renderView = () => {
    switch (view) {
      case 'day':
        const date = selectedDate
        return (
                    <div>
                      {/* Day view content */}
                      {/* <p>Selected Date: {selectedDate?.format('YYYY-MM-DD')}</p> */}
                      {renderDateCell(date)}
                    </div>
                  );
      case 'week':
        const startOfWeek = selectedDate.clone().startOf('week');
        const endOfWeek = selectedDate.clone().endOf('week');
        const weekDates = [];
        let weekDate = startOfWeek.clone();
        while (weekDate.isSameOrBefore(endOfWeek)) {
          weekDates.push(weekDate.clone());
          weekDate.add(1, 'day');
        }
        return (
                    <div>
                      {/* Week view content */}
                      {weekDates.map((date, index) => (
                        <div key={index}>{renderDateCell(date)}</div>
                      ))}
                    </div>
                  );
      case 'month':
        const startOfMonth = selectedDate.clone().startOf('month').startOf('week');
        const endOfMonth = selectedDate.clone().endOf('month').endOf('week');
        const monthDates = [];
        let currentDate = startOfMonth.clone();

        while (currentDate.isSameOrBefore(endOfMonth)) {
          monthDates.push(currentDate.clone());
          currentDate.add(1, 'day');
        }

        const rows = [];
        for (let i = 0; i < monthDates.length; i += 7) {
          rows.push(monthDates.slice(i, i + 7));
        }

        return (
          <div>
           {rows.map((row, rowIndex) => (
               <div key={rowIndex} style={{ display: 'flex', justifyContent: 'center' }}>
                 {row.map((date, index) => (
                   <div key={index} style={{ width: '14%', textAlign: 'center' }}>
                     {date ? renderDateCell(date) : null}
                   </div>
                 ))}
               </div>
             ))}
          </div>
        );
      default:
        return null;
    }
  };

  const renderDateCell = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');
    const cellEvents = events[formattedDate] || [];
    const isCurrentDate = date.isSame(moment(), 'day');
    const currentDateStyle = isCurrentDate ? { borderRadius: '60%', backgroundColor: '#E2E7F8' } : {};

    return (
      <Droppable droppableId={formattedDate} key={formattedDate}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              background: snapshot.isDraggingOver ? '#e0e4f7' : '#fff',
              border: '1px solid #e8e8e8',
              margin: '1px',
              display: 'flex',
              flexDirection: 'column',
              height: "120px",
              padding: '4px',
            }}
          >
             <style>
          {`
        div::-webkit-scrollbar {
          width: 8px;
          height: 2px;
        }
        div::-webkit-scrollbar-track {
          background-color: #f1f1f1;
        }
        div::-webkit-scrollbar-thumb {
          background-color: #C1C1C1;
          border-radius: 4px;
          min-width: 8px;
          max-width: 8px;
          min-height: 8px;
          max-height: 8px;
        }
        div::-webkit-scrollbar-thumb:hover {
          background-color: #7D7D7D;
        }
        `}
        </style>
            <div style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 600, display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ ...currentDateStyle, padding: "2px 6px", color: "#192228", opacity: 0.6 }}>{date.format('D')}</span>
              <span style={{ color: "#192228", opacity: 0.6 }}>{date.format('ddd')}</span>
            </div>
            <div style={{ flex: 1, overflowY: 'auto', marginTop: '4px' }}>
              {cellEvents.map((event, index) => (
                <Draggable draggableId={event.id.toString()} index={index} key={event.id}>
                  {(provided, snapshot) => (
                    <Popover
                      content={
                        <div style={{
                          maxHeight: '100px',
                          maxWidth: '200px',
                          overflowY: 'auto',
                          overflowX: 'hidden',
                        }}>
                          {cellEvents
                            .filter(e => e.recordId === event.recordId && !e.isHeading)
                            .map((filteredEvent, subIndex) => (
                              <div style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "12px" }} key={subIndex}>
                                {filteredEvent.title}
                              </div>
                            ))}
                        </div>
                      }
                      placement="topLeft"
                      trigger="hover"
                    >
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => history.push(`/window/${windowDefinition.ad_window_id}/${event.recordId}`)}
                        style={{
                          ...provided.draggableProps.style,
                          marginBottom: "1vh",
                          height: "3vh",
                          color: "#192228",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: event.isHeading ? 'bold' : 400,
                          padding: "0 0.5em",
                          display: event.isHeading ? 'flex' : 'none',
                          justifyContent: 'space-between',
                          borderLeft: event.isHeading ? `3px solid ${event.title_field_color || '#276EFA'}` : '',
                          borderTopLeftRadius: '3px',
                          borderBottomLeftRadius: '3px',
                          cursor: "pointer",
                        }}
                      >
                        <span className='formRecordTitle1' style={{ fontFamily: "Inter", fontSize: "12px" }}>
                          {event.isHeading ? event.title : null}
                        </span>
                      </div>
                    </Popover>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    );
  };

  const navigateToday = () => {
    setSelectedDate(moment());  // Navigate to today's date
  };

  const datePickerRef = React.useRef(null);

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.focus();  // Focus on the DatePicker to open it
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <Row gutter={16} style={{marginRight:"",marginLeft:""}} align="middle">
          <Col span={10}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <LeftOutlined onClick={navigatePrevious} style={{ marginRight: '8px' }}/>
            <RightOutlined onClick={navigateNext} style={{ marginLeft: '8px' }}/>&nbsp;{" "}
              {/* <Button icon={} onClick={navigatePrevious} style={{ marginRight: '8px' }} /> */}
              <div style={{ textAlign: 'start', color: '#192228', fontFamily: 'Inter', fontSize: '20px', fontWeight: 600 }}>
                {view === 'day' && selectedDate?.format('MMMM D, YYYY')}
                {view === 'week' && `${selectedDate?.startOf('week').format('MMM D')} - ${selectedDate?.endOf('week').format('MMM D, YYYY')}`}
                {view === 'month' && selectedDate?.format('MMMM YYYY')}
              </div> &nbsp;{" "}
              <img src={down} alt='' />  {/* Click to open DatePicker */}
              {/* <DatePicker
                ref={datePickerRef}  // Attach the ref
                style={{ opacity: 0, position: 'absolute', pointerEvents: 'none' }}  // Hide the DatePicker but make it functional
                value={selectedDate}
                onChange={onChange}
              /> */}
              {/* <Button icon={<RightOutlined />} onClick={navigateNext} style={{ marginLeft: '8px' }} /> */}
            </div>
          </Col>
          {/* <Col span={1}/> */}
          <Col span={7}>
            <Button.Group style={{ background: "#2F3856", borderRadius: "20px", fontFamily: "Inter", fontSize: "11px", fontWeight: 500, height: "5vh", padding: "7px",marginBottom:"0.5vh" }}>
              {['day', 'week', 'month'].map((viewType) => (
                <Button
                  key={viewType}
                  style={{
                    background: view === viewType ? '#fff' : '#2F3856',
                    color: view === viewType ? '#2F3856' : '#fff',
                    borderRadius: view === viewType ? '20px' : "",
                    border: "none",
                    height: "3vh",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => handleViewChange(viewType)}
                >
                  {viewType.charAt(0).toUpperCase() + viewType.slice(1)}
                </Button>
              ))}
            </Button.Group>
          </Col>
          <Col span={7} 
            style={{textAlign:"end"}}>
            <Button style={{ fontFamily: "Inter", fontSize: "13px", fontWeight: 700, height: "5vh" }} onClick={navigateToday}>Today</Button> {" "}
            <img src={listMode} onClick={() => props.setCalenderView(false)} style={{marginRight:"0.5vw",height:"4.5vh",cursor:"pointer"}} alt=''/>
            {/* <DatePicker value={selectedDate} onChange={onChange} /> */}
          </Col>
        </Row>
        <div style={{ height: "75vh", overflowY: "scroll", paddingBottom: "1vh" }}>{renderView()}</div>
      </div>
    </DragDropContext>
  );
};

export default CalenderView;
